var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-1"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('div',{staticClass:"text-h6"},[_vm._v("Logs")]),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('div',{staticClass:"mt-2"},[_c('v-text-field',{staticClass:"mt-4",attrs:{"outlined":"","prepend-inner-icon":"mdi-magnify","dense":"","clearable":"","label":"Search"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('v-autocomplete',{staticClass:"mx-1",staticStyle:{"max-width":"300px"},attrs:{"label":"Filter by Employees","items":_vm.workers,"item-text":"fullname","item-value":"uuid","prepend-inner-icon":"mdi-account-search","clearable":"","hide-details":"","outlined":"","dense":""},on:{"change":_vm.filterLogs,"click:clear":function($event){return _vm.resetFilter('employees')}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.fullname)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.rol)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.phone)}})],1)]]}}]),model:{value:(_vm.bodyreq.employeeUuid),callback:function ($$v) {_vm.$set(_vm.bodyreq, "employeeUuid", $$v)},expression:"bodyreq.employeeUuid"}}),_c('v-layout',{staticClass:"ma-4"},[_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-3"},[_c('ma-date-picker',{attrs:{"datepickerProps":{
              'no-title': true,
              max: _vm.maxDate,
            },"label":"From","past":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-col',{staticClass:"ml-2 mt-3"},[_c('ma-date-picker',{attrs:{"datepickerProps":{
              'no-title': true,
              max: _vm.maxDate,
            },"label":"To"},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1)],1)],1),_c('v-layout',{staticClass:"pa-4",attrs:{"row":""}},[_c('v-layout',{attrs:{"column":""}},[_c('div',{staticClass:"mr-2 text-capitalize"},[_vm._v(" "+_vm._s(_vm.filterRanges.replace("-", " "))+" ")]),_c('v-layout',{staticClass:"text-body-1 text--secondary",attrs:{"column":""}},[_c('div',[_vm._v(" From: "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.prettyDate(_vm.bodyreq.dates.date1)))])]),_c('div',[_vm._v(" To: "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.prettyDate(_vm.bodyreq.dates.date2)))])])])],1)],1),_c('v-divider'),[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"uuid","footer-props":_vm.footerProps,"server-items-length":_vm.totallogs,"options":_vm.options,"headers":_vm.headers,"items":_vm.systemLogs},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prettyDate(item.createAt))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.gotoLog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View Details")])])]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }